import React, { useRef } from 'react';
import { useParalaxElemets } from '../../hooks/useParalaxElements';

export const ShowflakeParalax = ({ containerRef, count, type }) => {
    const ref = useRef();
    useParalaxElemets({
        count,
        containerOptions: {
            className: 'snowflake-big',
            containerRef: ref,
            relativeContainerRef: containerRef,
        },
        sizeOptions: {
            minSize: 50,
            maxSize: 200,
        },
        paralaxOptions: {
            speed: 0.5,
            type
        },
        pulseOptions: {
            pulse: false,
        },
    });
    return <div ref={ref}></div>;
};
