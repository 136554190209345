import React from 'react';

export const Triangle = () => {
    return (
        <svg width="284" height="280" viewBox="0 0 284 280" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_f_141_590)">
                <path d="M120.386 31.6463C107.017 17.8518 83.5983 23.5786 78.233 41.9545L25.0173 224.215C19.652 242.591 36.3638 259.834 55.0986 255.253L240.919 209.812C259.654 205.231 266.36 182.261 252.991 168.466L120.386 31.6463Z" fill="#5FAF2D"/>
            </g>
            <defs>
            <filter id="filter0_f_141_590" x="0" y="0" width="284" height="280" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feGaussianBlur stdDeviation="12" result="effect1_foregroundBlur_141_590"/>
            </filter>
            </defs>
        </svg>

    );
};
