import { useParalax } from "../../hooks/useParalaxElements";

export const TreeWithLigth = ({ top, className = "" }) => {
	return (
		<div
			className={"treeWithLightImg " + className}
			style={{ top: top ?? "1500px" }}
		></div>
	);
};

export const TreeWithLigthRight = ({ top, className = "" }) => {
	return (
		<div
			className={"treeWithLightRightImg " + className}
			style={{ top: top ?? "1500px" }}
		></div>
	);
};
export const TreeWithLigthRight2 = ({ top, className = "" }) => {
	return (
		<div
			className={"treeWithLightRight2Img " + className}
			style={{ top: top ?? "1500px" }}
		></div>
	);
};

export const TreeWithLigthGroup = () => {
	useParalax("treeWithLightImg", 0.5, false);
	useParalax("treeWithLightRightImg", 0.7, false);
	useParalax("treeWithLightRight2Img", 0.6, false);
	return (
		<div>
			<TreeWithLigth top={"100px"}></TreeWithLigth>
			<TreeWithLigth top={"1500px"}></TreeWithLigth>
			<TreeWithLigth top={"3500px"}></TreeWithLigth>
			<TreeWithLigth top={"5500px"}></TreeWithLigth>
			<TreeWithLigth top={"7500px"}></TreeWithLigth>

			<TreeWithLigthRight top={"100px"}></TreeWithLigthRight>
			<TreeWithLigthRight top={"1500px"}></TreeWithLigthRight>
			<TreeWithLigthRight top={"3500px"}></TreeWithLigthRight>
			<TreeWithLigthRight top={"5500px"}></TreeWithLigthRight>
			<TreeWithLigthRight top={"7500px"}></TreeWithLigthRight>

			<TreeWithLigthRight2 top={"300px"}></TreeWithLigthRight2>
			<TreeWithLigthRight2 top={"1800px"}></TreeWithLigthRight2>
			<TreeWithLigthRight2 top={"3800px"}></TreeWithLigthRight2>
			<TreeWithLigthRight2 top={"5800px"}></TreeWithLigthRight2>
			<TreeWithLigthRight2 top={"7800px"}></TreeWithLigthRight2>
		</div>
	);
};
