import React, {useEffect, useMemo, useRef, useState} from 'react';
import {LightParalax} from "../../paralaxElements/Light";
import {ShowflakeParalax} from "../../paralaxElements/Snowflakes";
import useAxios from "axios-hooks";
import useLocalStorage from "../../../hooks/useLocalStorage";
import {useNavigate} from "react-router-dom";
import {HOST, AUHT_TOKEN} from '../../../env';
import {useModal} from "../../../hooks/useModal";
import {RegistrationModal} from "../../modals/RegistrationModal";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import {ErrorModal} from "../../modals/ErrorModal";

const WarningGreen = () => <img src="img/registration/warning-green.svg" alt="Warning"/>;
const TreeLeft = () => <img src="img/registration/tree-left.svg" alt="TreeLeft"/>;
const TreeLeftBlur = () => <img src="img/registration/tree-left-blur.svg" alt="TreeLeftBlur"/>;
const GiftBig = () => <img src="img/registration/gift-big.svg" alt="GiftBig"/>;
const GiftSmall = () => <img src="img/registration/gift-small.svg" alt="GiftSmall"/>;
const Letter = () => <img src="img/registration/letter.svg" alt="Letter"/>;
const Logo = () => <img src="img/registration/logox5.svg" alt="Logo"/>;
const LogoMobile = () => <img src="img/registration/logox5mobile.svg" alt="LogoMobile"/>;

const options = [
	'Пятёрочка',
	'Перекрёсток',
	'Чижик',
	'Х5 Digital',
	'Х5 Tech',
	'5Post',
	'Много Лосося',
	'Х5 Еда',
	'Управляющая Компания (вкл. Блок Безопасность)',
	'Х5 Media (включая Food.ru)',
	'Х5 Поддержка Бизнеса',
	'БЕ Впрок',
	'БЕ ОКОЛО',
	'БЕ Х5 Клиентский опыт',
	'Х5 Import',
	'Х5 Транспорт',
];
const defaultOption = 'Выбери свою бизнес-единицу';

const successesInfo = {
	title: "Супер, регистрация прошла успешно!",
	text: "Теперь вперёд – делиться и исполнять мечты!",
}

export const Registration = (props) => {

	const ref = useRef(null);
	const [t_number, setT_number] = useState(null);
	const [org, setOrg] = useState(null)
	const [isChecked, setIsChecked] = useState(false);
	const [register, setRegister] = useLocalStorage([], 'isRegistered');
	const navigate = useNavigate();
	const {handleOpen, X5Modal} = useModal({Component: RegistrationModal});

	const [{}, execute] = useAxios({
		url: HOST + '/items/logins',
		method: 'POST',
		headers: {
			Authorization: AUHT_TOKEN,
		},
	}, {manual: true});

	const disabled = useMemo(() => !t_number || !org || !isChecked, [t_number, org, isChecked]);
	const isRender = useMemo(() => register.length > 0, [])

	useEffect(() => {
		if (isRender) {
			navigate('/')
		}
	}, []);

	const handleNewLogin = async () => {
		if (isChecked) {
			const res = await execute({data: {t_number, org}});
			if (res.status === 204) {
				const modalClass = 'x5-modal__registration x5-modal confirm-wish-modal--dark-green';
				handleOpen({...successesInfo, register: t_number}, {modalClass});
			}
		}
	};

	const checkRules = (isChecked) => {
		setIsChecked(isChecked);
	}

	return (
		<>
			{
				!isRender && <>
					<div className="x5-modal__registration">
						<X5Modal></X5Modal>
					</div>
					<LightParalax containerRef={ref}></LightParalax>
					<ShowflakeParalax containerRef={ref}></ShowflakeParalax>
					<div className="registration-x5__container">
						<div className="registration-x5__title">Добро пожаловать <br/> в Мечтаграм!</div>
						<div className="registration-x5__title-text">Регистрация</div>
						<div className="registration-x5__title-desc"><WarningGreen></WarningGreen> Страница создана
							специально для сотрудников Х5 Group. При входе,
							пожалуйста, используй свой корпоративный табельный номер.
						</div>
						<form action="" className='registration-x5__form'>
							<div className="registration-table__input">
								<input className="registration-x5-text-input" placeholder="Введи свой табельный номер"
									   onChange={(e) => {
										   setT_number(e.target.value)
									   }}
									   type="text"></input>
							</div>
							<Dropdown className="registration-x5-text-input registration-table__select"
									  controlClassName='reg-Dropdown-control'
									  menuClassName='reg-Dropdown-menu'
									  arrowClassName='registration-select'
									  options={options}
									  onChange={(e) => setOrg(e.value)}
									  value={defaultOption}
									  placeholder="Выбери свою бизнес-единицу"/>;
						</form>
						<div className="registration-checkbox">
							<input type="checkbox" className="reg-custom-checkbox" id="regx5" name="regx5" value="yes"
								   onChange={(e) => checkRules(e.target.checked)}/>
							<label for="regx5">
								<div>
									Я согласен на обработку своих персональных данных в соответствии
									с положениями <a
									href="https://portal.x5.ru/Lists/Regulation/Безопасность/Политика%20в%20отношении%20обработки%20персональных%20данных%20в%20ООО%20«Корпоративный%20центр%20ИКС%205».pdf"
									target="_blank">Политики обработки персональных данных</a>
								</div>
							</label>
						</div>
						<div className="registration-button">
							<button
								className="x5-button x5-button--yes"
								onClick={async () => {
									await handleNewLogin();
									checkRules();
								}}
								disabled={!!disabled}>
								Отправить
							</button>
						</div>
						<div className="reg-logo">
							<Logo></Logo>
						</div>
						<div className="reg-logo-mobile">
							<LogoMobile></LogoMobile>
						</div>
					</div>
					{/*<div className="registration-back reg-tree__left">*/}
					{/*	<TreeLeft></TreeLeft>*/}
					{/*</div>*/}
					{/*<div className="registration-back reg-tree__left-blur">*/}
					{/*	<TreeLeftBlur></TreeLeftBlur>*/}
					{/*</div>*/}

					<div className="registration-back reg-gift__big">
						<GiftBig></GiftBig>
					</div>
					<div className="registration-back reg-gift__small">
						<GiftSmall></GiftSmall>
					</div>
					<div className="registration-back reg-letter">
						<Letter></Letter>
					</div>
				</>
			}
		</>

	);
};
