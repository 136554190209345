import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import { NewWishPage } from "./components/pages/NewWishPage";
import { Page } from "./components/Page";
import { LandingPage } from "./components/pages/landingpage/LandingPage";
import { Registration } from "./components/pages/registration/Registration";
import { Wishes } from "./components/pages/wishes/Wishes";
import { useModal } from "./hooks/useModal";
import { ErrorModal } from "./components/modals/ErrorModal";
import useLocalStorage from "./hooks/useLocalStorage";
import axios from "axios";

const errorInterceptor = {
	setupInterceptors: (setError) => {
		axios.interceptors.response.use(
			(response) => {
				return response;
			},
			(error) => {
				if (error.code !== "ERR_CANCELED") setError(true);
				return Promise.reject(error);
			},
		);
	},
};

const App = () => {
	const { handleOpen, X5Modal } = useModal({ Component: ErrorModal });
	const [error, setError] = useState(false);

	errorInterceptor.setupInterceptors(setError);

	useEffect(() => {
		if (error) {
			handleOpen(
				{},
				{ modalClass: "x5-modal confirm-wish-modal--dark-red" },
			);
		}
	}, [error]);

	return (
		<>
			<X5Modal></X5Modal>
			<Routes>
				<Route
					path="/"
					element={
						<Page
							lightCount={50}
							showflakeCount={20}
							needContactBlock={false}
						>
							<LandingPage></LandingPage>
						</Page>
					}
				/>
				<Route
					path="/newwish"
					element={
						<Page lightCount={20} showflakeCount={8}>
							<NewWishPage></NewWishPage>
						</Page>
					}
				></Route>
				<Route
					path="/wishes"
					element={
						<Page lightCount={20} showflakeCount={8}>
							<Wishes />
						</Page>
					}
				/>
				<Route
					path="/registration"
					element={
						<Page
							lightCount={20}
							showflakeCount={8}
							needContactBlock={false}
							needNavBlock={false}
						>
							<Registration />
						</Page>
					}
				/>
			</Routes>
		</>
	);
};

export default App;
