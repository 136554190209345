export const BoxGroup1 = (props) => {
	return (
		<div className={"boxGroup"}>
			<div
				className={
					"boxGroup__img boxGroup--margin-r-m10prc swing  box box__img"
				}
			>
				<img src="/img/boxes/box1.png" />
			</div>
			<div
				className={
					"boxGroup__img boxGroup--margin-l-m10prc  swing-right  box box__img"
				}
			>
				<img src="/img/boxes/box2.png" />
			</div>
		</div>
	);
};

export const BoxSingle = (props) => {
	return (
		<div className={"boxGroup"}>
			<div
				className={
					"boxGroup__img boxGroup--margin-r0 swing box box__img"
				}
			>
				<img src="/img/boxes/box3.png" />
			</div>
		</div>
	);
};

export const BoxSingleText = ({ title, subtitle }) => {
	return (
		<div className={"boxSingleWithText box-container"}>
			<BoxSingle></BoxSingle>
			<div className={"boxSingleWithText__text"}>
				<div className={"boxSingleWithText__title title"}>{title}</div>
			</div>
		</div>
	);
};

export const BoxGroupWithText = ({ margin = "15rem" }) => {
	return (
		<div
			className={"boxGroupWithText box-container"}
			style={{ margin }}
		>
			<BoxGroup1></BoxGroup1>
			<div className={"boxGroupWithText__text"}>
				<div className={"boxGroupWithText__title title"}>Мечтаграм</div>
				<div className={"boxGroupWithText__subtitle subtitle"}>
					место, где исполняются мечты!
				</div>
			</div>
		</div>
	);
};
