import React from 'react';
import {useModal} from "../../hooks/useModal";
import {WishModal} from "../modals/WishModal";

export const WishRow = ({currentItems}) => {
    const {handleOpen, X5Modal} = useModal({Component: WishModal});
    const handleOpenModal = (wish) => {
        const modalClass = wish.id % 2 === 0 ? 'x5-modal confirm-wish-modal--dark-green' : 'x5-modal';
        handleOpen(wish, {modalClass});
    };
    return (
        <>
            <X5Modal></X5Modal>
            {currentItems && currentItems.map((item) => (
                <div className="wish-row-container" style={{cursor: 'pointer'}}
                     onClick={() => {
                         handleOpenModal(item);
                     }}>
                    <div className="figure-row__text">Мечта</div>
                    <div className="figure-row__text">#{item.id}</div>
                    <div className="wish-row__text">
                        <span>{item.wish}</span>
                    </div>
                </div>
            ))
            }
        </>
    );
};
