import { useEffect } from "react";

export const useParalaxElemets = ({
	count,
	containerOptions,
	sizeOptions,
	paralaxOptions,
	pulseOptions,
	gridOptions,
}) => {
	let elements = [];

	const { speed, type } = paralaxOptions;
	const { pulse } = pulseOptions;

	useEffect(() => {
		const { relativeContainerRef, containerRef, className } =
			containerOptions;
		const container = containerRef.current;
		const relativeContainer = relativeContainerRef.current;

		if (!container || !relativeContainer) {
			return;
		}

		const handleResize = () => {
			if (elements.length) {
				for (const e of elements) {
					container.removeChild(e);
				}
			}

			const res = createElements(
				className,
				relativeContainer,
				container,
				count,
				sizeOptions,
				pulse,
				gridOptions
			);
			elements = res.elements;
			return addParalaxSwiped(elements, speed);
		};

		// const resizeObserver = new ResizeObserver(handleResize);
		// resizeObserver.observe(relativeContainer);

		// // Initial call to handleResize
		handleResize();

		// Cleanup function to disconnect the observer
		return () => {
		    // resizeObserver.disconnect();
		};
	}, [count]);
};

export const useParalax = (className, speed, needRandom) => {
	useEffect(() => {
		if (className) {
			const elements = [...document.getElementsByClassName(className)];
			addParalaxSwiped(elements, speed, needRandom);
		}
	}, [className]);
};

function createElements(
	className,
	relativeContainer,
	container,
	count,
	sizeOptions,
	pulse,
	gridOptions
) {
	const snowflakePositions = [];
	const elements = [];

	for (let i = 0; i < count; i++) {
		const snowflake = document.createElement("div");
		elements.push(snowflake);

		snowflake.className = className;
		pulse && addRandomPulseEffect(snowflake);
		createBlur(snowflake);
		createSize(snowflake, sizeOptions);

		container.appendChild(snowflake);
	}

	const windowWidth = window.innerWidth;
	const windowHeight =
		relativeContainer.offsetHeight < window.innerHeight
			? window.innerHeight
			: relativeContainer.offsetHeight;
	distributeElementsInGrid(
		elements,
		windowWidth,
		windowHeight,
		gridOptions,
		sizeOptions,
		container
	);

	return { elements, initPositions: snowflakePositions };
}

function createBlur(snowflake) {
	const blurAmount = Math.random() * 3;
	snowflake.style.filter = `blur(${blurAmount}px)`;
}

function createSize(snowflake, sizeOptions) {
	const { minSize, maxSize } = sizeOptions;
	const size = Math.random() * (maxSize - minSize) + minSize;
	snowflake.style.width = size + "px";
	snowflake.style.height = size + "px";

	return size;
}

function distributeElementsInGrid(
	elements,
	containerWidth,
	containerHeight,
	gridOptions,
	sizeOptions
) {
	if (!elements.length) return; // Проверка на пустой массив элементов

	let defaultGridOptions = {
		padding: 10,
		startColCount: undefined,
		endColCount: undefined,
		skipStartCols: 0,
		skipEndCols: 0,
		minDistance: 300,
		minDistanceFromCenter: 200,
	};

	let {
		padding,
		startColCount,
		endColCount,
		skipStartCols,
		skipEndCols,
		minDistance,
		minDistanceFromCenter,
	} = { ...defaultGridOptions, ...gridOptions };

	const elementWidth = sizeOptions.maxSize;
	const elementHeight = sizeOptions.maxSize;

	// Вычисляем размеры сетки
	const cols = Math.floor(containerWidth / elementWidth);
	const rows = Math.floor(containerHeight / elementHeight);

	// Если `startColCount` или `endColCount` не указаны, заполняем все колонки
	startColCount = startColCount ?? cols;
	endColCount = endColCount ?? 0;

	const allowedColumns = [
		...Array.from({ length: startColCount }, (_, i) => skipStartCols + i),
		...Array.from(
			{ length: endColCount },
			(_, i) => cols - skipEndCols - endColCount + i
		),
	];

	const uniqueAllowedColumns = [...new Set(allowedColumns)];

	const maxElements = uniqueAllowedColumns.length * rows;
	if (maxElements < elements.length) {
		elements = elements.slice(0, maxElements);
	}

	const cells = [];
	for (let row = 0; row < rows; row++) {
		for (const col of uniqueAllowedColumns) {
			cells.push({
				x: col * elementWidth + padding,
				y: row * elementHeight + padding,
			});
		}
	}

	// Перемешиваем массив ячеек случайным образом
	shuffleArray(cells);

	const placedCells = [];
	const centerX = containerWidth / 2;
	const centerY = containerHeight / 2;

	elements.forEach((element, index) => {
		if (index >= cells.length) return;

		let position;
		let isValidPosition = false;

		// Поиск подходящей позиции
		while (!isValidPosition) {
			position = cells.pop();
			if (!position) return; // Если закончились ячейки

			isValidPosition =
				placedCells.every((placedCell) => {
					const distanceSquared =
						Math.pow(position.x - placedCell.x, 2) +
						Math.pow(position.y - placedCell.y, 2);
					return distanceSquared >= Math.pow(minDistance, 2);
				}) &&
				Math.pow(position.x - centerX, 2) +
					Math.pow(position.y - centerY, 2) >=
					Math.pow(minDistanceFromCenter, 2);
		}

		placedCells.push(position);
		element.style.position = "absolute";
		element.style.left = `${position.x}px`;
		element.style.top = `${position.y}px`;
	});

	function shuffleArray(array) {
		for (let i = array.length - 1; i > 0; i--) {
			const j = Math.floor(Math.random() * (i + 1));
			[array[i], array[j]] = [array[j], array[i]];
		}
	}
}

function addRandomPulseEffect(element) {
	// Генерация случайных значений для параметров анимации
	const randomScale = 1 + Math.random() * 0.2; // Масштаб от 1 до 1.2
	const randomOpacity = 0.5 + Math.random() * 0.5; // Прозрачность от 0.5 до 1
	const randomDuration = 500 + Math.random() * 1000; // Длительность от 500 до 1500 мс

	// Создаем ключевые кадры для анимации
	const keyframes = [
		{ transform: "scale(1)", opacity: 1, offset: 0 },
		{
			transform: `scale(${randomScale})`,
			opacity: randomOpacity,
			offset: 0.5,
		},
		{ transform: "scale(1)", opacity: 1, offset: 1 },
	];

	// Создаем анимацию
	const animation = element.animate(keyframes, {
		duration: randomDuration, // Длительность анимации в миллисекундах
		iterations: Infinity, // Бесконечное количество повторений
		easing: "ease-in-out", // Функция плавности анимацииы
	});

	// Возвращаем объект анимации, чтобы можно было его контролировать
	return animation;
}

function addParalaxSwiped(elements, speed, relativeContainer) {
	const getSpeed = () => {
		return true ? Math.random() * (speed - 0.005) + 0.005 : speed;
	};

	// Сохраняем начальные позиции и фиксированные скорости
	const initialData = Array.from(elements).map((element) => ({
		initialTop: element.offsetTop,
		speed: getSpeed(), // Случайная скорость для элемента
	}));
	let ticking = false;

	const handleScroll = (scrollY) => {
		if (!ticking) {
			window.requestAnimationFrame(() => {
				elements.forEach((element, index) => {
					const { initialTop, speed } = initialData[index];
					if (initialTop !== undefined) {
						const offset = scrollY * speed; // Используем фиксированную скорость
						element.style.transform = `translateY(${offset}px)`;

						// Проверяем видимость элемента относительно контейнера
						// const containerRect =
						// 	relativeContainer.getBoundingClientRect();
						// const elementRect = element.getBoundingClientRect();
						// const isVisible =
						// 	elementRect.top >= containerRect.top &&
						// 	elementRect.bottom <= containerRect.bottom;

						// Устанавливаем стиль display в зависимости от видимости
						// element.style.display = isVisible ? "block" : "none";
					}
				});

				ticking = false;
			});
			ticking = true;
		}
	};

	// todo отписаться
	window.addEventListener("wheelScrolled", (e) => {
		handleScroll(e.detail.offset);
	});
}

// function addParalaxScroll(elements, speed, needRandom) {
// 	// Сохраняем начальные позиции и фиксированные скорости
// 	const initialData = Array.from(elements).map((element) => ({
// 		initialTop: element.offsetTop,
// 		speed: Math.random() * (speed - 0.005) + 0.005, // Случайная скорость для элемента
// 	}));
// 	let ticking = false;

// 	const handleScroll = (scrollY) => {
// 		if (!ticking) {
// 			window.requestAnimationFrame(() => {
// 				elements.forEach((element, index) => {
// 					const { initialTop, speed } = initialData[index];
// 					if (initialTop !== undefined) {
// 						const offset = scrollY * speed; // Используем фиксированную скорость
// 						element.style.transform = `translateY(${offset}px)`;
// 					}
// 				});

// 				ticking = false;
// 			});
// 			ticking = true;
// 		}
// 	};

// 	// todo отписаться
// 	window.addEventListener("wheelScrolled", (e) => {
// 		handleScroll(window.scrollY);
// 	});
// }
