import useAxios from "axios-hooks";
import React from "react";
import { useModal } from "../../hooks/useModal";
import { WishModal } from "../modals/WishModal";
import { Wish } from "../wish/Wish";
import { HOST, AUHT_TOKEN } from "../../env";
import { BoxSingleText } from "../MechtagramBox";

const mockData = [
	{
		wish: "Хочу обзавестись собственной уютной квартирой в центре города.",
		id: 109,
	},
	{
		wish: "Мечтаю купить новый автомобиль последней модели.",
		id: 110,
	},
	{
		wish: "Очень хочу новенький игровой ноутбук для работы и отдыха.",
		id: 111,
	},
	{
		wish: "Планирую купить профессиональную фотокамеру для творчества.",
		id: 112,
	},
	{
		wish: "Желаю приобрести современный смартфон с лучшими характеристиками.",
		id: 113,
	},
	{
		wish: "Планирую купить большой загородный дом с садом.",
		id: 114,
	},
	{
		wish: "Планирую купить профессиональную фотокамеру для творчества.",
		id: 115,
	},
	{
		wish: "Мечтаю накопить на роскошный отпуск на Мальдивах.",
		id: 116,
	},
	{
		wish: "Планирую купить большой загородный дом с садом.",
		id: 117,
	},
	{
		wish: "Мечтаю купить новый автомобиль последней модели.",
		id: 118,
	},
	{
		wish: "Желаю приобрести современный смартфон с лучшими характеристиками.",
		id: 119,
	},
	{
		wish: "Желаю приобрести стильные брендовые часы.",
		id: 120,
	},
];

export const WishList = (props) => {
	const { handleOpen, X5Modal } = useModal({ Component: WishModal });
	const [{ data, loading, error }] = useAxios({
		url: HOST + "/items/wishes",
		method: "GET",
		params: {
			limit: 25,
			fields: ["wish", "id"],
			sort: ["id"],
			page: 1,
			filter: {
				_or: [
					{ wish_state: { _eq: 'accept' } },
					{ force_show: { _eq: true } }
				]
			},
		},
		headers: {
			Authorization: AUHT_TOKEN,
		},
	});

	const handleOpenModal = (wish) => {
		const modalClass =
			wish.id % 2 === 0
				? "x5-modal"
				: "x5-modal confirm-wish-modal--dark-green";
		handleOpen(wish, { modalClass });
	};

	if (loading) return <p>Loading...</p>;

	let res = [];
	if (error) {
		res = mockData;
	}
	if (!loading && !error) {
		res = data.data;
	}

	return (
		<>
			<div style={{ marginBottom: "15rem" }}>
				<BoxSingleText title={"Мечтаграм"}></BoxSingleText>
			</div>
			<div className="wishList" style={{ marginTop: "-200px" }}>
				<div className="wish-list-container">
					<X5Modal></X5Modal>
					<div className="wish-list">
						{[...res, ...res, ...res].map((w, i) => (
							<div
								key={i}
								style={{ cursor: "pointer" }}
								onClick={() => {
									handleOpenModal(w);
								}}
							>
								<Wish wishData={w}></Wish>
							</div>
						))}
					</div>
				</div>
				<div className="wishListBtn">
					<a className="x5-button x5-button--yes" href="/wishes">
						Посмотреть ещё
					</a>
				</div>
			</div>
		</>
	);
};
