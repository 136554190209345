import { useRef, useState } from "react";
import { useModal } from "../../hooks/useModal";
import { ConfirmWishModal } from "../modals/ConfirmWish";

const Box1 = () => <img src="img/newwish/box.svg" />;
const Box2 = () => <img src="img/newwish/box2.svg" />;
const Light1 = () => <img src="img/newwish/light.svg" />;

const NewWishRules = () => {
	return (
		<div className="new-wish-rules">
			<div className="new-wish-rules__rule">
				<img src="/img/newwish/new-wish-rule-1.png" />
			</div>
			<div className="new-wish-rules__rule">
				<img src="/img/newwish/new-wish-rule-2.png" />
			</div>
			<div className="new-wish-rules__rule">
				<img src="/img/newwish/new-wish-rule-3.png" />
			</div>
		</div>
	);
};

export const NewWishPage = () => {
	const textRef = useRef(null);
	const t_number = localStorage.getItem('isRegistered');
	const [org, setOrg] = useState(null);
	const [wish, setWish] = useState(null);
	const { handleOpen, X5Modal } = useModal({
		Component: ConfirmWishModal,
		afterClose: () => {
		},
	});

	const handleNewWish = () => {
		handleOpen({ wish: { t_number, org, wish } });
	};

	const disabled = !t_number || !wish;

	return (
		<div className="new-wish-page">
			<div className="new-wish">
				<div className="new-wish__background"></div>
				<div className="new-wish__title-container">
					<div className="new-wish__heade-image">
						<div className="newWishHeader"></div>
						<img src="img/newwish/stars.svg" />
					</div>
					<div className="new-wish__title new-wish__title--top">
						Следуй
					</div>
					<div className="new-wish__title new-wish__title--buttom">
						за мечтой
					</div>
				</div>
				<div className="new-wish__content">
					<NewWishRules></NewWishRules>
					<div className="new-wish__link">
					<a  href='rules/Мотивационная_акция_Мечтаграм_20241208_2.pdf' target="_blank">Изучи условия конкурса</a>
					</div>
					<div className="new-wish__text">
						В поле ниже введи описание твоей мечты – в течение 3
						дней она пройдет модерацию и будет опубликована в
						Мечтаграме. Помни! Важно, чтобы твоя мечта была
						реализуемой.
					</div>
					<div className="new-wish__input">
						<textarea
							ref={textRef}
							className="x5-text-input"
							placeholder="Опиши свою мечту"
							type="text"
							onChange={(e) => setWish(e.target.value)}
						></textarea>
					</div>
					<div className="new-wish__button">
						<button
							className="x5-button x5-button--yes"
							onClick={handleNewWish}
							disabled={!!disabled}
						>
							Отправить
						</button>
					</div>
				</div>
			</div>

			<div className="mobile-hide">
				<div className="new-wish__right-panel">
					<div className="new-wish-page__box2">
						<div className="new-wish-page__box2--light"></div>
						<Box2></Box2>
					</div>
					<div className="new-wish-page__light1">
						<Light1></Light1>
					</div>
				</div>
				<div className="new-wish__left-panel">
					<div className="new-wish-page__box-light"></div>
					<div className="new-wish-page__box">
						<div className="new-wish-page__box--light"></div>
						<Box1></Box1>
					</div>
				</div>
			</div>
			<X5Modal></X5Modal>
		</div>
	);
};
