import React, {useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import useLocalStorage from "../../hooks/useLocalStorage";

export const RegistrationModal = (data) => {
	const navigate = useNavigate();
	const [register, setRegister] = useLocalStorage([], 'isRegistered');
	const closeModal = () => {
		navigate('/')
	}

	useEffect(() => {
		setRegister([data.register])
	}, [])

	return (
		<div className="confirm-wish-modal">
			<div className="confirm-wish-modal__title">{data.title}</div>
			<div className="confirm-wish-modal__text">{data.text}</div>
			<div className="confirm-wish-modal__buttons">
				<button className="x5-button x5-button--yes" onClick={closeModal}>
					К мечтам!
				</button>
			</div>
		</div>
	);
};
