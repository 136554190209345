import useAxios from 'axios-hooks';
import React from 'react';
import { useModal } from '../../hooks/useModal';
import { ConfirmedWishModal } from '../modals/ConfirmedWish';
import { HOST, AUHT_TOKEN } from '../../env';
import { useNavigate } from "react-router-dom";

export const ConfirmWishModal = ({ close, wish }) => {
	const navigate = useNavigate();
    const { handleOpen, X5Modal } = useModal({
        Component: ConfirmedWishModal,
        afterClose: () => {
            close();
            navigate("/wishes");
        },
    });
    const [{ data, loading, error }, execute] = useAxios({
        url: HOST + '/items/wishes',
        method: 'POST',
        headers: {
            Authorization: AUHT_TOKEN,
        },
    }, {manual: true});

    const handleNewWish = async () => {
        const res = await execute({ data: wish });
        if (res.status === 200 && res.data?.data?.id) {
            handleOpen({ id: res.data?.data?.id });
        }
    };

    return (
        <div className="confirm-wish-modal">
            <div className="confirm-wish-modal__title">Всё готово?</div>
            <div className="confirm-wish-modal__text">
                Тогда жми «Да, отправляю!» и твоя мечта уйдёт на проверку модераторам. Если она соответствует условиям конкурса, она появится в Мечтаграме в
                течение 3х дней.
            </div>
            <div className="confirm-wish-modal__link">
            <a href='rules/Мотивационная_акция_Мечтаграм_20241208_2.pdf' target="_blank">Читать условия конкурса</a>   
            </div>
            <div className="confirm-wish-modal__buttons">
                <button className="x5-button x5-button--yes" onClick={handleNewWish}>
                    Да, отправляю!
                </button>
                <button className="x5-button x5-button--no" onClick={close}>
                Нет, дополнить мечту
                </button>
            </div>
            <X5Modal></X5Modal>
        </div>
    );
};
