import React, { useRef } from 'react';
import { LightParalax } from "../../paralaxElements/Light";
import { ShowflakeParalax } from "../../paralaxElements/Snowflakes";
import { WishTable } from "../../wishtable";

export const Wishes = (props) => {
    const ref = useRef(null);
    return (
            <div id="paralax-container" ref={ref}>
                <LightParalax containerRef={ref}></LightParalax>
                <ShowflakeParalax containerRef={ref}></ShowflakeParalax>
                <WishTable/>
            </div>
    );
};
