import React from 'react';
import { Autoplay } from 'swiper/modules';


export const ShareDream2 = () => {
    return (
        <>
        <img className='shareDreamImg' src="img/share-wish.svg" />
        <div className='shareDreamSection'>
        <img className='shareDreamSectionImg' src="img/date.svg" />
        <div className="shareFrame">
            <p>
                В розыгрыше случайным образом будут выбраны
                <br />
                5 сотрудников из каждой бизнес-единицы,
                <br />  и именно их мечты и будут исполнены!
                <br />
            </p>
            <p>
                Дополнительно благотворительный фонд X5 Group
                <br />
                «Выручаем» выберет ещё 5 участников, сделавших
                <br />  пожертвования, чтобы исполнить и их мечты.
            </p>
        </div>
        </div>
        </>
    ) 
};

export const ImageMagic = () => {
    return <img className='swing' src="img/presentMagic.png" />;
};

export const ShareRules = () => {
    return (
        <>
            <div className='blurFrame'>
            <div className="rules">
                <div className="rule1">
                    <img className='ruleLetterImg' src="img/ruleLetter.png" />
                    <p>
                        Расскажи о своей мечте с
                        <br />
                        с 09.12.2024 г. по 08.01.2025 г.
                        <br />
                        включительно
                    </p>
                </div>
                <div className="rule2">
                    <img className='ruleClocksImg' src="img/ruleClocks.png" />
                    <p>
                        Жди розыгрыша
                        <br />
                        15.02.2025 г.
                    </p>
                </div>
                <div className="rule3">
                    <img className='ruleArtImg' src="img/ruleArt.png" />
                    <p>
                        Ищи себя среди 80-ти<br />
                        счастливчиков, победивших<br />
                        в рандомайзере
                    </p>
                </div>
                <div className="rule4">
                    <img className='ruleHandsImg' src="img/ruleHands.png" />
                    <p>
                        Или среди 5-и участников,<br />
                        которых выберет фонд<br />
                        «Выручаем»
                    </p>
                </div>
            </div>
            <div className="shareBtn">
                <a className="x5-button x5-button--yes" href='/newwish'>Поделиться мечтой</a>
                <a className='pdfLink' href='rules/Мотивационная_акция_Мечтаграм_20241208_2.pdf' target="_blank">Читать условия конкурса</a>   
            </div>
            </div>
        </>
    );
};
