import React from 'react';

export const MainModal = ({close}) => {
    return (
        <div className="confirm-wish-modal">
            <div className="confirm-wish-modal__title">Дорогие мечтатели!</div>
            <div className="confirm-wish-modal__text">
				Мы уже приступили к модерации :)
				<br /> Желаний очень много, на их проверку нам понадобится больше, чем 72 часа.
				<br />Надеемся на ваше понимание!</div>
            <div className="confirm-wish-modal__buttons">
                <button className="x5-button x5-button--yes" onClick={close}>
                    Хорошо
                </button>
            </div>
        </div>
    );
};
