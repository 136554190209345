import React from "react";

export const CompleteDreamDesc = () => {
	return (
		<>
			<img className="completeDreamImg" src="img/do-wish.svg" />
			<div className="completeDream">
				<div className="completeFrame">
					<img
						className="completeLogo"
						src="img/logo.svg"
					/>
					<p>
						Мы мечтаем о красивых и значительных вещах, но порой
						самые искренние желания скромны и жизненно важны — как,
						например, мечта о тёплом обеде. Исполнить мечту того,
						кому сейчас нужна еда, очень просто.
					</p>
					<div className="doDreamText">
						<p>
							Нажимай на кнопку «Исполнить мечту», переходи на
							сайт благотворительного фонда «Выручаем» и делай
							пожертвование. Собранные средства пойдут на
							продуктовые наборы для нуждающихся, а ты получишь
							бейдж «Исполнитель мечт» на внутреннем портале.
						</p>
					</div>
				</div>
				<div className="completeDreamBtn">
					<a
						className="x5-button x5-button--yes"
						href="https://xn--80adk2ars2a3a.xn--p1ai/help/mechtagram-x5?utm_source=internal-communications&utm_medium=click&utm_campaign=internal-communications-outside"
						target="_blanc"
					>
						Исполнить мечтy
					</a>
				</div>
			</div>
		</>
	);
};
