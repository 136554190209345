import React from 'react';
import {useNavigate} from "react-router-dom";

export const ErrorModal = () => {
	const navigate = useNavigate();
	const closeModal = () => {
		navigate('/')
	}
	return (
		<div className="confirm-wish-modal">
			<div className="confirm-wish-modal__title">Упс, что-то пошло не так =(</div>
			<div className="confirm-wish-modal__text">Обновите страницу или зайдите позже.</div>
			<div className="confirm-wish-modal__buttons">
				<button className="x5-button x5-button--yes" onClick={closeModal}>
					OK
				</button>
			</div>
		</div>
	);
};
