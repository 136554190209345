import React from 'react';
import { Star } from '../Star';
import { Triangle } from '../Triangle';

export const Wish = ({ wishData }) => {
    const { wish, id } = wishData;
    return (
        <div className="wish">
            <div className="wish-container">
                <div className="wish__figure">
                    <div className="figure">{id % 2 === 0 ? <Star></Star> : <Triangle></Triangle>}</div>
                    <div className="figure__text figure__text--top">мечта</div>
                    <div className="figure__text figure__text-bottom">#{id}</div>
                </div>
                <div className="wish__text">{wish}</div>
                <div className="wish__background"></div>
            </div>
        </div>
    );
};
