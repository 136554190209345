import React, {useState, useEffect} from "react";
import {useHistory, useNavigate} from "react-router-dom";

export const Navigation = (props) => {
	const [activeSection, setActiveSection] = useState(window.location.hash);
	const elementHeader = () => document.getElementById("landingPage");
	const elementShareDreams = () => document.getElementById("shareDream");
	const elementDoWish = () => document.getElementById("dowish");
	const elementFAQ = () => document.getElementById("faq");
	const elementContact = () => document.getElementById("contact");
	const navigate = useNavigate();

	const scrollFunc = (wish) => {
		if (!document.location.hash) {
			navigate("/#" + wish);
		}
			switch (wish) {
				case "landingpage":
					elementHeader().scrollIntoView({
						behavior: "smooth",
						block: "start",
						inline: "nearest",
					});
					break;
				case "shareDream":
					elementShareDreams().scrollIntoView({
						behavior: "smooth",
						block: "start",
						inline: "nearest",
					});
					break;
				case "dowish":
					elementDoWish().scrollIntoView({
						behavior: "smooth",
						block: "start",
						inline: "nearest",
					});
					break;
				case "faq":
					elementFAQ().scrollIntoView({
						behavior: "smooth",
						block: "start",
						inline: "nearest",
					});
					break;
				case "contact":
					const srollContainer = document.getElementsByClassName(
						"x5-scroll-container"
					)[0];
					srollContainer.scrollTo(0, srollContainer.scrollHeight);
					break;
		}
	};

	const [isOpen, setIsOpen] = useState(false);
	const menuItems = [
		<div
			className={
				activeSection === "" ? "x5-menu__item active" : "x5-menu__item"
			}
			onClick={() => {
				setIsOpen(false);
				return setActiveSection("");
			}}
		>
			<a
				className={"menu-navigation"}
				onClick={(e) => scrollFunc("landingpage")}
			>
				Мечтаграм
			</a>
		</div>,
		<div
			className={
				activeSection === "#shareDream"
					? "x5-menu__item active"
					: "x5-menu__item"
			}
			onClick={() => {
				setIsOpen(false);
				return setActiveSection("#shareDream");
			}}
		>
			<a
				className={"menu-navigation"}
				onClick={(e) => scrollFunc("shareDream")}
			>
				Поделиться мечтой
			</a>
		</div>,
		<div
			className={
				activeSection === "#dowish"
					? "x5-menu__item active"
					: "x5-menu__item"
			}
			onClick={() => {
				setIsOpen(false);
				return setActiveSection("#dowish");
			}}
		>
			<a
				className={"menu-navigation"}
				onClick={(e) => scrollFunc("dowish")}
			>
				Исполнить мечту
			</a>
		</div>,
		<div
			className={
				activeSection === "#faq"
					? "x5-menu__item active"
					: "x5-menu__item"
			}
			onClick={() => {
				setIsOpen(false);
				return setActiveSection("#faq");
			}}
		>
			<a
				className={"menu-navigation"}
				onClick={(e) => scrollFunc("faq")}
			>
				FAQ
			</a>
		</div>,
		<div
			className={
				activeSection === "#contact"
					? "x5-menu__item active"
					: "x5-menu__item"
			}
			onClick={() => {
				setIsOpen(false);
				return setActiveSection("#contact");
			}}
		>
			<a

				onClick={(e) => scrollFunc("contact")}
			>
				Контакты
			</a>
		</div>,
	];
	useEffect(() => {
		window.onhashchange = () => {
			setActiveSection(window.location.hash);
		};
	}, []);

	return (
		<>
			<nav className="x5-navbar x5-navbar--desctop">
				<div className="x5-navbar__navbar-header">
					<div className="x5-navbar__logo">
						<a href="/" >
							<img src={'/img/logo.svg'} />
						</a>
					</div>
					<div className="x5-navbar__menu x5-menu">{menuItems}</div>
				</div>
			</nav>
			<nav role="navigation" className="x5-navbar x5-navbar--mobile">
				<div className="x5-navbar__logo">
						<a href="/" >
							<img src={'/img/logo.svg'}/>
						</a>
				</div>
				<div id="menuToggle">
					<input
						type="checkbox"
						checked={isOpen}
						onChange={(e) => {
							setIsOpen(e.target.checked);
						}}
					/>
					<span></span>
					<span></span>
					<span></span>
					<div id="menu" className="menu">
						<div className="menu__container">
							<div className="menu__items x5-menu">
								{menuItems.slice(0, menuItems.length - 1)}
							</div>
							<div className="menu__footer ">
								<img src={'/img/menu-logo.svg'} style={{height: '100%', margin: 'auto'}}/>
							</div>
						</div>
					</div>
				</div>
			</nav>
		</>
	);
};
