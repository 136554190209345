import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useState } from 'react';

export const useModal = ({ Component, afterClose }) => {
    const [open, setOpen] = useState(false);
    const [modalOptions, setModalOptions] = useState({});
    const [modalProps, setModalProps] = useState({});
    const handleOpen = (modalProps, { modalClass } = {}) => {
        setModalProps(modalProps);
        setModalOptions({ modalClass });
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        afterClose && afterClose();
    };

    const X5Modal = () => (
        <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <div className={modalOptions?.modalClass ?? 'x5-modal'}>
                <div className="x5-modal__close" onClick={handleClose}>
                    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.30885e-05 3.81817L3.81819 0L42 38.1818L38.1818 41.9999L1.30885e-05 3.81817Z" fill="white" />
                        <path d="M38.1818 7.82091e-05L42 3.81825L3.81818 42L0 38.1818L38.1818 7.82091e-05Z" fill="white" />
                    </svg>
                </div>
                <Box>{Component && <Component close={handleClose} {...modalProps}></Component>}</Box>
            </div>
        </Modal>
    );

    return { handleOpen, handleClose, X5Modal };
};
