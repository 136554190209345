import React, { useRef } from 'react';
import { useParalaxElemets } from '../../hooks/useParalaxElements';

export const LightParalax = ({ containerRef, count, type }) => {
    const ref = useRef();
    useParalaxElemets({
        count,
        containerOptions: {
            className: 'snowflake',
            containerRef: ref,
            relativeContainerRef: containerRef,
        },
        sizeOptions: {
            minSize: 15,
            maxSize: 60,
        },
        paralaxOptions: {
            speed: 0.1,
            type
        },
        pulseOptions: {
            pulse: false,
        },
    });
    return <div ref={ref}></div>;
};
