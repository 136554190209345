import React from 'react';

export const Star = () => {
    return (
        <svg width="272" height="280" viewBox="0 0 272 280" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_f_141_587)">
                <path d="M95.4053 34.4994C97.4225 24.9263 108.78 20.7879 116.467 26.8252L161.955 62.5529C164.659 64.6771 168.087 65.6597 171.507 65.2912L229.036 59.0945C238.757 58.0474 246.191 67.5725 242.82 76.7578L222.876 131.115C221.691 134.346 221.813 137.913 223.217 141.053L246.834 193.875C250.825 202.801 244.061 212.826 234.292 212.466L176.478 210.333C173.04 210.206 169.688 211.427 167.136 213.737L124.203 252.579C116.948 259.143 105.335 255.814 102.667 246.406L86.8802 190.73C85.9416 187.42 83.7476 184.609 80.7659 182.896L30.6151 154.08C22.1405 149.21 21.7264 137.127 29.8472 131.673L77.9045 99.3975C80.7617 97.4785 82.7579 94.5196 83.4677 91.1514L95.4053 34.4994Z" fill="#5FAF2D"/>
            </g>
            <defs>
                <filter id="filter0_f_141_587" x="0" y="0" width="272" height="280" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feGaussianBlur stdDeviation="12" result="effect1_foregroundBlur_141_587"/>
                </filter>
            </defs>
        </svg>

    );
};
