import { useState, useEffect } from "react";

function useLocalStorage(initialValue, key) {
    const getValue = () => {
        const storage = localStorage.getItem(key);

        if (storage) {
            return JSON.parse(storage);
        }
        return initialValue;
    }

    const [value, setValue] = useState(getValue);

    useEffect(() => {
        if (value != '')
            localStorage.setItem(key, JSON.stringify(value));
        else
            return;
    }, [value]);

    return [value, setValue];
}

export default useLocalStorage;