import React from "react";

export const Header = (props) => {
	return (
		<div className="intro">
			<div className="col intro-text">
				<h1>Мечтаграм</h1>
				<div className="underHeader">
					<p className="x5Text">X5 Group исполнит мечту!</p>
				</div>
				<div className="frameLetter">
					<img className="letter" src="img/letter.png" />
					<div className="headerFrame">
						<p>
							Расскажи о своей
							<br />
							мечте или исполни
							<br />
							мечту других!
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};
