import React from "react";

const StarSvg = () => {
	return (
		<svg
			width="432"
			height="451"
			viewBox="0 0 432 451"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g opacity="0.15" filter="url(#filter0_f_239_559)">
				<path
					d="M141.335 29.5054C145.045 11.7208 165.935 4.03268 180.073 15.2486L263.738 81.622C268.712 85.5682 275.017 87.3936 281.308 86.7092L387.12 75.1972C405 73.2519 418.672 90.9472 412.473 108.011L375.79 208.993C373.609 214.997 373.834 221.622 376.417 227.456L419.855 325.587C427.196 342.17 414.756 360.794 396.787 360.124L290.45 356.161C284.128 355.925 277.962 358.195 273.267 362.485L194.302 434.645C180.959 446.839 159.598 440.654 154.691 423.176L125.655 319.745C123.928 313.595 119.893 308.373 114.409 305.19L22.167 251.656C6.57983 242.61 5.81828 220.163 20.7548 210.031L109.146 150.07C114.401 146.505 118.073 141.008 119.378 134.751L141.335 29.5054Z"
					fill="#003E14"
				/>
			</g>
			<defs>
				<filter
					id="filter0_f_239_559"
					x="0"
					y="0"
					width="432"
					height="451"
					filterUnits="userSpaceOnUse"
					color-interpolation-filters="sRGB"
				>
					<feFlood flood-opacity="0" result="BackgroundImageFix" />
					<feBlend
						mode="normal"
						in="SourceGraphic"
						in2="BackgroundImageFix"
						result="shape"
					/>
					<feGaussianBlur
						stdDeviation="5"
						result="effect1_foregroundBlur_239_559"
					/>
				</filter>
			</defs>
		</svg>
	);
};

const TriangleSvg = () => {
	return (
		<svg
			width="462"
			height="452"
			viewBox="0 0 462 452"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g opacity="0.15" filter="url(#filter0_f_239_568)">
				<path
					d="M190.52 24.2379C165.481 -1.44833 121.621 9.21532 111.572 43.4325L11.9052 382.815C1.85663 417.032 33.1559 449.14 68.2439 440.609L416.264 355.995C451.352 347.464 463.912 304.692 438.873 279.006L190.52 24.2379Z"
					fill="#5FAF2D"
				/>
			</g>
			<defs>
				<filter
					id="filter0_f_239_568"
					x="0"
					y="0"
					width="462"
					height="452"
					filterUnits="userSpaceOnUse"
					color-interpolation-filters="sRGB"
				>
					<feFlood flood-opacity="0" result="BackgroundImageFix" />
					<feBlend
						mode="normal"
						in="SourceGraphic"
						in2="BackgroundImageFix"
						result="shape"
					/>
					<feGaussianBlur
						stdDeviation="5"
						result="effect1_foregroundBlur_239_568"
					/>
				</filter>
			</defs>
		</svg>
	);
};

export const WishModal = ({ id = 1, wish, close }) => {
	const Img = id % 2 === 0 ? TriangleSvg : StarSvg;

	return (
		<div className="confirm-wish-modal">
			<div className="confirm-wish-modal__content">
				<div className="confirm-wish-modal__title">Мечта #{id}</div>
				<div className="scroll-container">
					<div className="confirm-wish-modal__text">{wish}</div>
				</div>
			</div>
			<div className="confirm-wish-modal__bg-image">
				<Img></Img>
			</div>
			<div className="confirm-wish-modal__buttons">
				<button className="x5-button x5-button--yes" onClick={close}>
					Круто
				</button>
			</div>
		</div>
	);
};
