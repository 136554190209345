import React from "react";

export const Contact = () => {
	return (
		<div className="contact" id="contact">
			<div className="section-title contactFooter">
				<h2>Контакты</h2>
				<p>mechtagram@х5.ru</p>
				<div className="mobile-hide">
					<div className="footer">
						<a
							href="https://выручаем.рф/help/mechtagram-x5?utm_source=internal-communications&utm_medium=click&utm_campaign=internal-communications-outside"
							target="_blank"
							style={{ maxWidth: "370px" }}
						>
							<img src="img/footerLogo.svg" />
						</a>
						<a
							href="https://newportal.x5.ru/news/x5/16493"
							target="_blanc"
							style={{ maxWidth: "180px" }}
						>
							<img src="img/footerLogo2.svg" />
						</a>
					</div>
				</div>
				<div className="mobile">
					<div>
						<a
							href="https://newportal.x5.ru/news/x5/16493"
							target="_blanc"
							className="toplogo1"
						>
							<img src="img/mobileTopLogo.svg" />
						</a>
						<a
							href="https://выручаем.рф/help/mechtagram-x5?utm_source=internal-communications&utm_medium=click&utm_campaign=internal-communications-outside"
							target="_blanc"
							className="link2"
						>
							<img src="img/mobileTopLogo2.svg" />
						</a>
					</div>
				</div>
			</div>
		</div>
	);
};
